<div class="row mt-2 back-btn-height">
  <div class="col-md-5 details-card">
    <div class="mt-2" *ngIf="selectedEvent?.detail || canEditEventUpdate">
      <div *ngIf="scanDistanceInfo?.scanDistance" class="text-danger my-2">
        Scanned outside configured checkpoint radius
      </div>
      <dl>
        <!--additional information-->
        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <dt>
              <span class="fw-bold">
                {{
                  selectedEvent?.action !== "Scanned Qr"
                    ? selectedEvent?.action
                    : "Scanned QR Checkpoint"
                }}
              </span>
              <ng-container *ngIf="editedDetails?.updated_at">
                <span>
                  | Edited [
                  {{ editedDetails?.updated_at | customDate : "d MMM y HH:mm" }}
                  ]
                </span>
              </ng-container>
            </dt>
          </div>
          <div
            *ngIf="canEditEventUpdate"
            (click)="editUserComment.emit(selectedEvent)"
            class="mx-2"
          >
            <span>
              <i
                class="fa-solid fa-pen-to-square md-font-size text-secondary"
              ></i>
            </span>
          </div>
          <div
            *ngIf="canDeleteRestoreCommentPhoto"
            (click)="
              deleteRestoreUserUpdate.emit({
                event: selectedEvent,
                type: selectedEvent?.is_deleted ? 'restore' : 'delete'
              })
            "
            class="ms-1"
          >
            @if(selectedEvent?.is_deleted){
            <span>
              <i
                class="fa-regular fa-square-plus md-font-size text-secondary"
              ></i>
            </span>
            }@else {
            <span>
              <i
                class="fa-regular fa-square-minus md-font-size text-secondary"
              ></i>
            </span>
            }
          </div>
        </div>
        <dd class="gt-multi-line">
          {{ selectedEvent?.detail }}
          <span *ngIf="selectedEvent?.eta"
            >[ETA:
            {{ selectedEvent?.eta | customDate : "d MMM y HH:mm" }} ]</span
          >
          <span
            *ngIf="
              selectedEvent?.user_provided_timestamp &&
              (selectedEvent?.user_provided_timestamp
                | customDate : 'd MMM y HH:mm') !=
                (selectedEvent?.updated_at | customDate : 'd MMM y HH:mm')
            "
          >
            [{{
              selectedEvent?.user_provided_timestamp
                | customDate : "d MMM y HH:mm"
            }}]
          </span>
          <ng-container
            *ngIf="
              selectedEvent?.event_action === 1 &&
              selectedEvent?.status_identity === 12 &&
              selectedEventFrom === 'job' &&
              selectedEvent?.event_add_info?.event_value
            "
          >
            <div>Reason: {{ selectedEvent?.event_add_info?.event_value }}</div>
          </ng-container>
        </dd>
        <div class="small-font text-secondary text-end">
          <ng-container *ngIf="deletedDetails?.updated_at">
            <div>
              Deleted:
              {{ deletedDetails?.updated_at | customDate : "d MMM y HH:mm" }} |
              {{ deletedDetails?.user_name }}
            </div>
          </ng-container>
          <ng-container
            *ngIf="restoredDetails?.updated_at && !selectedEvent?.is_deleted"
          >
            Restored:
            {{ restoredDetails?.updated_at | customDate : "d MMM y HH:mm" }} |
            {{ restoredDetails?.user_name }}
          </ng-container>
        </div>
      </dl>

      <hr />
    </div>

    <div class="row row-cols-1 row-cols-xxl-2 mb-2">
      <ng-container *ngFor="let indColumn of userEventDetailsTableView">
        <ng-container
          *ngIf="
            (indColumn.nestedValue
              ? indColumn.nestedValue(selectedEvent)
              : selectedEvent[indColumn.name]
            )?.trim()
          "
        >
          <div>
            <dl>
              <dt class="text-uppercase">
                {{ indColumn?.header }}
              </dt>
              <!--style whole row either based on condition or common to all -->
              <div *ngIf="indColumn?.datetimeObj == true">
                <!--a row is typically divided into 3 prefix, main content and suffix, style each of them based on conditions or common -->
                <span
                  [ngStyle]="
                    indColumn?.conditionalPrefixStyle
                      ? indColumn?.conditionalPrefixStyle(this.selectedEvent)
                      : indColumn?.prefixStyle
                  "
                >
                  <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                  {{
                    indColumn?.prefixNestedValue
                      ? indColumn?.prefixNestedValue(this.selectedEvent)
                      : indColumn?.prefix
                      ? indColumn?.prefix
                      : ""
                  }}
                </span>
                <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
                <!-- main content which will be passed from the backend STARTS-->
                <span
                  [ngStyle]="
                    indColumn?.conditionalColumnDataStyle
                      ? indColumn?.conditionalColumnDataStyle(
                          this.selectedEvent
                        )
                      : indColumn?.columnDataStyle
                  "
                >
                  {{
                    indColumn.nestedValue
                      ? (indColumn.nestedValue(this.selectedEvent)
                        | customDate
                          : (indColumn.dateTimeFormat
                              ? indColumn.dateTimeFormat
                              : "d MMM y HH:mm"))
                      : (this.selectedEvent[indColumn.name]
                        | customDate
                          : (indColumn.dateTimeFormat
                              ? indColumn.dateTimeFormat
                              : "d MMM y HH:mm"))
                  }}
                </span>
                <!-- main content which will be passed from the backend ENDS-->
                <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                <span
                  [ngStyle]="
                    indColumn?.conditionalSuffixStyle
                      ? indColumn?.conditionalSuffixStyle(this.selectedEvent)
                      : indColumn?.suffixStyle
                  "
                >
                  {{
                    indColumn?.suffixNestedValue
                      ? indColumn?.suffixNestedValue(this.selectedEvent)
                      : indColumn?.suffix
                      ? indColumn?.suffix
                      : ""
                  }}
                </span>
                <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
              </div>
              <!-- rows where objects are datetime objects ENDS-->

              <!-- rows where objects are normal objects STARTS-->

              <!--style whole row either based on condition or common to all -->
              <div *ngIf="indColumn?.datetimeObj !== true">
                <!--a row is typically divided into 3 prefix, main content and suffix, style each of them based on conditions or common -->
                <span
                  [ngStyle]="
                    indColumn?.conditionalPrefixStyle
                      ? indColumn?.conditionalPrefixStyle(this.selectedEvent)
                      : indColumn?.prefixStyle
                  "
                >
                  <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                  {{
                    indColumn?.prefixNestedValue
                      ? indColumn?.prefixNestedValue(this.selectedEvent)
                      : indColumn?.prefix
                      ? indColumn?.prefix
                      : ""
                  }}
                </span>
                <!-- prefix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
                <!-- main content which will be passed from the backend STARTS-->
                <span
                  [ngStyle]="
                    indColumn?.conditionalColumnDataStyle
                      ? indColumn?.conditionalColumnDataStyle(
                          this.selectedEvent
                        )
                      : indColumn?.columnDataStyle
                  "
                >
                  {{
                    indColumn.nestedValue
                      ? indColumn.nestedValue(this.selectedEvent)
                      : this.selectedEvent[indColumn.name] == "null" ||
                        this.selectedEvent[indColumn.name] == "undefined"
                      ? "N/A"
                      : this.selectedEvent[indColumn.name]
                  }}
                </span>
                <!-- main content which will be passed from the backend ENDS-->
                <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) STARTS-->
                <span
                  [ngStyle]="
                    indColumn?.conditionalSuffixStyle
                      ? indColumn?.conditionalSuffixStyle(this.selectedEvent)
                      : indColumn?.suffixStyle
                  "
                >
                  {{
                    indColumn?.suffixNestedValue
                      ? indColumn?.suffixNestedValue(this.selectedEvent)
                      : indColumn?.suffix
                      ? indColumn?.suffix
                      : ""
                  }}
                </span>
                <!-- suffix (either hardcoded from ui or pased from backend via key-value pair) ENDS-->
              </div>
            </dl>
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="selectedEvent?.checkpoint_details?.min_distance">
        <dl>
          <dt class="text-uppercase">Geofence Radius (metres)</dt>
          <dd>
            {{
              selectedEvent?.checkpoint_details?.min_distance | number : "1.0-0"
            }}
          </dd>
        </dl>
      </div>
    </div>

    <div
      *ngIf="selectedEvent?.recorded_at && !selectedEvent?.delayed_event"
      class="mb-2"
    >
      <dl>
        <dt class="text-danger fw-bold d-flex align-items-center">
          CAPTURED TIME
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            class="ms-2"
            [attr.title]="'This event was captured when the user was offline'"
          >
            <i class="fa-solid fa-circle-info"></i>
          </span>
        </dt>
        <dd>{{ selectedEvent?.recorded_at | date : "d MMM y HH:mm" }}</dd>
      </dl>
    </div>

    <ng-container *ngIf="selectedEvent?.prompts?.length">
      <div *ngFor="let item of selectedEvent?.prompts" class="my-3">
        <div class="fw-bold">
          {{ item?.prompt_data?.name ? item?.prompt_data?.name : "Prompt" }}
        </div>

        <div *ngFor="let dataItem of item?.data">
          <ng-template
            *ngTemplateOutlet="promptTemplate; context: { value: dataItem }"
          ></ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="selectedEvent?.device_details && (isAdmin || isDispatchUser)"
    >
      <div class="row row-cols-1 row-cols-lg-2">
        <div *ngFor="let item of selectedEvent?.device_details | keyvalue">
          <dl *ngIf="item?.value">
            <dt class="text-uppercase">
              {{ item.key }}
            </dt>
            <dd class="text-break">{{ item.value }}</dd>
          </dl>
        </div>
        <div *ngIf="browserGpsInfo?.[selectedEvent?.gps?.source]?.description">
          <dl>
            <dt>GPS Accuracy</dt>
            <dd>
              {{ browserGpsInfo?.[selectedEvent?.gps?.source]?.description }}
            </dd>
          </dl>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="col-md-7">
    <div
      [ngClass]="selectedEvent?.file ? 'row row-cols-md-2' : 'row row-cols-1'"
    >
      <div
        class="col-md-6 my-2"
        *ngIf="selectedEvent?.file"
        (click)="onOpenFile(selectedEvent)"
      >
        <img
          class="cursor-pointer mw-100 border border-secondary"
          src="{{ selectedEvent?.file }}"
          alt="unplash"
        />
      </div>
      <div
        [ngClass]="selectedEvent?.file ? 'col-md-6' : 'col'"
        *ngIf="isAdmin || isDispatchUser"
      >
        <gtapp-map-leaflet
          *ngIf="selectedEvent?.gps?.lat"
          [latLon]="selectedEvent?.gps"
          [scannedCheckpointDetails]="selectedEvent?.checkpoint_details"
          [mapId]="'event-location'"
        ></gtapp-map-leaflet>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3 bottom-section" id="fixedBtn">
  <div class="px-3 pb-2">
    <button
      class="btn btn-dark d-block d-md-none w-100 btn-lg"
      (click)="hideDetails()"
    >
      Back
    </button>
  </div>
</div>

<!--looped prompt template-->
<ng-template let-dataItem="value" #promptTemplate>
  <div *ngIf="dataItem?.description">
    <dl>
      <dt class="text-uppercase">{{ dataItem?.description }}</dt>

      <dd>{{ dataItem | misc : "promptValue" }}</dd>
    </dl>
  </div>

  <div *ngFor="let option of dataItem?.options">
    <ng-template
      *ngTemplateOutlet="promptTemplate; context: { value: option }"
    ></ng-template>
  </div>
</ng-template>
