import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private message = new BehaviorSubject<any>({
    successMessage: '',
    errorMessage: '',
    timeOut: 5000,
    status: null,
  });
  constructor() {}
  setMessage(message: any) {
    this.notifyUser();
    this.message.next(message);
  }
  returnMessage() {
    return this.message.asObservable();
  }

  playChord(duration: number, frequency: number, volume: number) {
    return new Promise<void>((resolve, reject) => {
      duration = duration || 200;
      volume = volume || 100;

      try {
        const audioCtx = new window.AudioContext();
        const oscillator = audioCtx.createOscillator();
        const gainNode = audioCtx.createGain(); // Create gain node for volume control

        oscillator.type = 'sine'; // Sine wave
        oscillator.frequency.setValueAtTime(frequency, audioCtx.currentTime);
        gainNode.gain.setValueAtTime(volume * 0.01, audioCtx.currentTime); // Set volume
        // gainNode.gain.value = volume * 0.01;

        oscillator.connect(gainNode);
        gainNode.connect(audioCtx.destination);
        oscillator.start(audioCtx.currentTime);
        oscillator.stop(audioCtx.currentTime + duration);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
  getUserData() {
    return JSON.parse(localStorage.getItem('userData') || '{}');
  }
  getNotificationVolume() {
    let volume = 30;
    const userPreferences = this.getUserData()?.preferences || {};
    // the variable used will seem strange. Initally there was only mute and unmute option. Recycling the key so that it wont be wasted.
    if ('mute_notification' in userPreferences) {
      if (userPreferences?.mute_notification === true) {
        volume = 0;
      } else if (userPreferences?.mute_notification === false) {
        volume = 30;
      } else {
        volume = userPreferences?.mute_notification;
      }
    } else {
      volume = 30;
    }
    return volume;
  }
  async notifyUser(actionResult: string = 'success') {
    let volume = this.getNotificationVolume();

    if (volume) {
      if (actionResult === 'success') {
        const melody = [
          { duration: 0.15, frequency: 587 },
          { duration: 0.15, frequency: 740 },
          { duration: 0.15, frequency: 587 },
        ];

        let time = 0;
        melody.forEach((note) => {
          setTimeout(() => {
            this.playChord(note.duration, note.frequency, volume);
          }, time * 1000);
          time += note.duration;
        });
      } else {
        this.playChord(0.2, 200, volume);
      }
    }
  }
}
