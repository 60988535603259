import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { DIALOG_DATA } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { PagesService } from '../../../pages/pages/pages.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';
import { UploadTypeComponent } from '../upload-type/upload-type.component';

@Component({
  selector: 'gtapp-web-cam-image',
  templateUrl: './web-cam-image.component.html',
  styleUrl: './web-cam-image.component.scss',
})
export class WebcamImageComponent implements OnInit, OnDestroy {
  showFrom: any;
  uploadType: string = 'live';

  imageUrl: any;

  actualUploadFile: any;
  fileData: any = [];

  btnLable: string = 'Save';
  newComment: any;

  isCameraOpen: boolean = false;
  apidataUpdation: boolean = true;
  handOverLogDraft: boolean = false;
  clientCount: number = 0;
  dialogeRef: any;

  groupUserCount = this.pageService.getGroupUserCount();
  constructor(
    protected camDialogRef: DialogRef,
    private pageService: PagesService,
    private dialogService: ModelDialogueService,
    @Inject(DIALOG_DATA) public dialogueData: any,
    private toasterService: ToasterService,
    private appService: AppService,
    private spinnerService: LoadingSpinnerService
  ) {}

  ngOnInit(): void {
    this.showFrom = this.dialogueData.showFrom;

    var ele: any = document.querySelector('.cdk-overlay-container');
    ele.style.backgroundColor = '#000000';
    this.newComment = null;

    if (this.showFrom === 'scanCheckpoint') {
      this.btnLable = 'Scan Checkpoint';
      this.uploadType = 'live';
      this.openCamera();
    } else {
      this.selectDeviceUploadType();
    }
    this.pageService.miscSubjectParam.subscribe((value: any) => {
      this.apidataUpdation = value?.addPhotoClientCount?.apidataUpdation;
      this.clientCount = value?.addPhotoClientCount?.clientCount;
    });
  }

  //File submit to parent component
  onFileSubmit() {
    let image = {
      actualFileUpload: this.actualUploadFile,
      uploadType: this.uploadType,
      newComment: this.newComment,
    };
    this.camDialogRef.close(image);
  }

  close() {
    this.camDialogRef.close();
  }

  //Showing this button only with update client or job
  onSavePhoto() {
    let image = {
      actualFileUpload: this.actualUploadFile,
      newComment: this.newComment,
      uploadType: this.uploadType,
      handOverLogDraft: this.handOverLogDraft,
    };
    this.camDialogRef.close(image);
  }

  ngOnDestroy(): void {
    var ele: any = document.querySelector('.cdk-overlay-container');
    ele.style.backgroundColor = null;
  }

  async handleFileInput(item: any) {
    let fileCheckError = await this.appService.fileCheckError(
      item.files.item(0)
    );
    if (fileCheckError) {
      this.toasterService.setMessage({
        errorMessage: fileCheckError,
        successMessage: '',
      });
    } else {
      this.spinnerService.show('Processing Image');
      this.actualUploadFile = await this.compressImage(item.files.item(0));

      console.log(this.actualUploadFile, 'uploaded');

      if (this.showFrom === 'scanCheckpoint') {
        this.onFileSubmit();
      } else {
        //Show image preview
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.imageUrl = event.target.result;
          this.spinnerService.hide();
        };
        reader.readAsDataURL(this.actualUploadFile);
        this.isCameraOpen = false;
      }
    }
  }
  openCamera() {
    const elementId =
      this.uploadType === 'live' ? 'fileInputLive' : 'fileInput';
    var button: any = document.getElementById(elementId);
    button.click();
    setTimeout(() => {
      this.isCameraOpen = true;
    }, 2000);
  }

  selectDeviceUploadType() {
    this.dialogeRef = this.dialogService.open(UploadTypeComponent, {});
    this.dialogeRef.afterClosed().subscribe((value: any) => {
      if (value === false) {
        this.camDialogRef.close();
      } else {
        this.uploadType = value;
        this.openCamera();
      }
    });
  }

  async compressImage(file: any) {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(file);

    await new Promise((resolve) => {
      img.onload = resolve;
    });

    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    const MAX_WIDTH = 1500; // Set your desired max width
    const scaleSize = Math.min(MAX_WIDTH / img.width, 1);
    canvas.width = img.width * scaleSize;
    canvas.height = img.height * scaleSize;

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    const compressedBlob: any = await new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        'image/avif',
        1
      );
    });

    return new File([compressedBlob], file.name, { type: 'image/avif' });
  }
}
